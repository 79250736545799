import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationMessage, NotificationMessageOptions } from '@phothor/shared/messages/notification-message';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notificationSource: Subject<NotificationMessage> = new Subject<NotificationMessage>();
  public notification$: Observable<NotificationMessage> = this.notificationSource.asObservable();

  constructor() {}

  showMessage(param: NotificationMessageOptions) {
    const notification = new NotificationMessage(param);
    this.notificationSource.next(notification);
  }
}
