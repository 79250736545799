import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationMessagesComponent } from '@phothor/shared/messages/components/notification-messages/notification-messages.component';
import { ErrorMessagesComponent } from '@phothor/shared/messages/components/error-messages.component';
import { ObjectKeysPipe } from '@phothor/shared/messages/pipes/object-keys.pipe';
import { ErrorMessagePipe } from '@phothor/shared/messages/pipes/error-message.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ProgressMessagesComponent } from '@phothor/shared/messages/components/progress-messages/progress-messages.component';
import {
  AlertTriangle,
  Check,
  CheckCircle,
  ChevronRight,
  Copy,
  Loader,
  LucideAngularModule, MoveRight,
  RefreshCw,
  XCircle,
  XSquare,
  X, Eye,
} from 'lucide-angular';
import { ShareModalComponent } from '@phothor/shared/messages/components/share-modal/share-modal.component';
import { TippyDirective } from '@ngneat/helipopper';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {NgxTurnstileModule} from "ngx-turnstile";
import {
  OnboardingMessagesComponent
} from "@phothor/shared/messages/components/onboarding-messages/onboarding-messages.component";

@NgModule({
  declarations: [
    NotificationMessagesComponent,
    ErrorMessagesComponent,
    ObjectKeysPipe,
    ErrorMessagePipe,
    ConfirmationModalComponent,
    ProgressMessagesComponent,
    ShareModalComponent,
    OnboardingMessagesComponent
  ],
  exports: [
    NotificationMessagesComponent,
    ErrorMessagesComponent,
    ObjectKeysPipe,
    ErrorMessagePipe,
    ConfirmationModalComponent,
    ProgressMessagesComponent,
    ShareModalComponent,
    OnboardingMessagesComponent
  ],
  imports: [
    CommonModule,
    LucideAngularModule.pick({
      CheckCircle,
      Check,
      XCircle,
      X,
      AlertTriangle,
      Copy,
      RefreshCw,
      Loader,
      MoveRight,
      Eye
    }),
    TippyDirective,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    NgxTurnstileModule,
  ],
})
export class MessagesModule {}
