<div class="fixed bottom-2 lg:left-3 px-8 lg:px-0 max-w-full lg:max-w-[40%] z-[100]">
  <ng-container *ngFor="let notification of notifications">

    <div class="toastify on toastify-right toastify-top transition-all duration-500" aria-live="polite"
         style="transform: translate(0px, 0px); top: 15px;"
         [ngClass]="{
          'animate-fadeOut': notification.isClosing,
          'animate-slideInFromOutside': notification.isOpening,
          'opacity-0': notification.isClosing
         }"
    >
      <div
        class="py-5 pl-5 pr-14 border border-slate-200/60 rounded-lg shadow-xl dark:bg-darkmode-600 dark:text-slate-300 dark:border-darkmode-600 flex"
        [ngClass]="{
        'bg-white': notification.color === 'white',
        'bg-blue-100': notification.color === 'blue',
        'bg-yellow-100': notification.color === 'yellow',
        'bg-rose-100': notification.color === 'rose',
        'bg-green-100': notification.color === 'green',
        'border-white-300': notification.color === 'white',
        'border-blue-300': notification.color === 'blue',
        'border-yellow-300': notification.color === 'yellow',
        'border-rose-300': notification.color === 'rose',
        'border-green-300': notification.color === 'green',
      }"
      >
        <lucide-icon *ngIf="notification.icon" name="{{ notification.icon }}"></lucide-icon>
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ notification.summary }}</div>
          <div class="mt-1 text-slate-500">
            {{ notification.detail }}
          </div>
        </div>
      </div>
      <button (click)="closeNotification(notification)" type="button" aria-label="Close" class="toast-close">✖</button>
    </div>
  </ng-container>
</div>
