import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfirmationMessage, ConfirmationMessageOptions } from '@phothor/shared/messages/confirmation-message';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  public confirmationSource: Subject<ConfirmationMessage> = new Subject<ConfirmationMessage>();
  public $confirmation: Observable<ConfirmationMessage> = this.confirmationSource.asObservable();

  constructor() {}

  confirm(param: ConfirmationMessageOptions) {
    const notification = new ConfirmationMessage(param);
    this.confirmationSource.next(notification);
  }
}
