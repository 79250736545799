import { tap } from 'rxjs';
import { AuthService, LoginResponse } from '@phothor/auth/auth.service';
import { Router } from '@angular/router';
import { LoginPayload } from '@phothor/auth/state/login.payload';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthState {
  private rememberMe: string | null = null;
  refreshTokenInterval?: any;

  getToken(): string {
    return localStorage.getItem('token') || '';
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  login(payload: LoginPayload) {
    return this.authService.login(payload).pipe(tap((result) => this.loginResponse(result)));
  }

  loginResponse(result: LoginResponse) {
    this.setToken(result.token);
    this.rememberMe = result.token;
  }

  logout() {
    this.clear();
    window.location.href = environment.host + '/account/login';
    return false;
  }

  refreshAuthToken() {
    return this.authService.refreshToken().pipe(
      tap((result: { token: string | null }) => {
        if (typeof result.token === 'string') {
          this.setToken(result.token);
        }
      }),
    );
  }

  clear() {
    if (this.refreshTokenInterval) {
      clearInterval(this.refreshTokenInterval);
    }
    localStorage.removeItem('token');
  }

  startTokenUpdater() {
    if (this.isAuthenticated() && !this.refreshTokenInterval) {
      this.refreshTokenInterval = setInterval(() => {
        this.refreshAuthToken().subscribe();
      }, 60 * 1000);
    }
  }
}
