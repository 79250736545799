import {Component, OnDestroy, OnInit,} from '@angular/core';
import {injectTippyRef, } from "@ngneat/helipopper";
import { OnboardingService } from '@phothor/shared/services/onboarding.service';

@Component({
  selector: 'app-onboarding-messages',
  templateUrl: './onboarding-messages.component.html',
})
export class OnboardingMessagesComponent{
  tippy = injectTippyRef();

  public title = '';
  public description = '';

  constructor(
    private onboardingService: OnboardingService,
  ) {
    this.title = this.tippy.data.title;
    this.description = this.tippy.data.description;
  }

  nextStep() {
    this.onboardingService.nextStep();
  }

  stop() {
    this.onboardingService.stopOnboarding();
  }
}
