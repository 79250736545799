import { Component } from '@angular/core';
import { NotificationMessage } from '@phothor/shared/messages/notification-message';
import { NotificationService } from '@phothor/shared/services/notification.service';

@Component({
  selector: 'app-notification-messages',
  templateUrl: './notification-messages.component.html',
})
export class NotificationMessagesComponent {
  notifications: NotificationMessage[] = [];

  constructor(private notificationService: NotificationService) {
    this.notificationService.notification$.subscribe((val) => {
      this.notifications.push(val);
      setTimeout(() => {
        this.closeNotification(val);
      }, val.life * 1000);
    });
  }

  closeNotification(notification: NotificationMessage) {
    notification.isClosing = true;
    setTimeout(() => {
      this.notifications = this.notifications.filter((n) => n !== notification);
    }, 300);
  }
}
