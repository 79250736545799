export class ConfirmationMessage {
  summary: string;
  detail: string;
  confirm: () => void = () => {};
  reject: () => void = () => {};

  constructor(options: ConfirmationMessageOptions) {
    this.summary = options.summary;
    this.detail = options.detail || '';
    this.confirm = options.confirm;
    this.reject = options.reject || (() => {});
  }
}

export interface ConfirmationMessageOptions {
  summary: string;
  detail?: string;
  confirm: () => void;
  reject?: () => void;
}
