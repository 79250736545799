import { Component } from '@angular/core';
import { JobsService } from '@phothor/shared/jobs/jobs.service';
import { Job } from '@phothor/shared/jobs/job';

@Component({
  selector: 'app-progress-messages',
  templateUrl: './progress-messages.component.html',
  styleUrls: ['./progress-messages.component.css'],
})
export class ProgressMessagesComponent {
  jobs: Map<string, Job> = new Map<string, Job>();

  constructor(private jobService: JobsService) {
    this.jobService.jobAddSubject.subscribe((job) => {
      this.jobs.set(job.id, job);
    });
    this.jobService.jobDeleteSubject.subscribe((job) => {
      this.jobs.delete(job.id);
    });
  }

  protected readonly Math = Math;

  public getJobs(): Job[] {
    return Array.from(this.jobs.values());
  }
}
