<div class="fixed bottom-2 lg:right-3 px-8 lg:px-0 max-w-full lg:max-w-[40%] z-50">
  <ng-container *ngFor="let job of getJobs()">
    <div class="min-w-full lg:min-w-[30rem] flex flex-col gap-2 bg-white
      px-3 py-3 rounded-xl shadow-xl border mt-2 transform"

    >
      <div class="flex justify-between w-full items-center">
        <span>{{ job.title }}</span>
        <span class="text-sm italic">
            {{ job.itemsCompleted }} / {{ job.itemsTotal }}
        </span>
      </div>
      <div class="py-2 relative">
        <div class="absolute h-[2px] bg-gray-300 w-full"></div>
        <div class="absolute h-[2px] bg-blue-500 transition-all duration-500 ease-in-out"
             [style.width]="Math.floor((job.itemsCompleted / job.itemsTotal) * 100) + '%'"></div>
      </div>
      <div class="max-h-64 text-sm font-light overflow-y-auto">
        <div *ngFor="let item of job.items; let i = index;"
             class="flex items-center justify-between px-2 py-1"
             [ngClass]="{
            'bg-gray-50': item.state === 'IN_PROGRESS',
            'bg-green-100': item.state === 'DONE',
            'bg-red-100': item.state === 'ERROR'
         }">
          <div>{{ item.title }}</div>
          <div>

            <div class="relative h-2 w-32 bg-gray-300">
              <div *ngIf="item.progressType === 'DETERMINATE'"
                   class="absolute h-2 bg-blue-500 transition-all duration-500 ease-in-out"
                   [style.width]="item.progress + '%'"></div>
              <div *ngIf="item.progressType === 'INDETERMINATE'"
                   class="absolute h-2 bg-blue-500 transition-all duration-500 ease-in-out animate-load"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
