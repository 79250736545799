import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginPayload } from '@phothor/auth/state/login.payload';
import { Profile } from '@phothor/auth/state/profile';
import { Router } from '@angular/router';

export interface LoginResponse {
  token: string;
  domain: string;
}

@Injectable()
export class AuthService {
  private profile!: Profile;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {}

  refreshToken(): Observable<{ token: string | null }> {
    return this.httpClient.get<{ token: string }>(environment.apiHost + '/api/refresh-token');
  }

  checkAuth(): Observable<boolean> {
    return this.httpClient.get<Profile>(environment.apiHost + '/api/auth/me').pipe(
      map((r) => {
        this.profile = r;
        if (this.profile.state == 'PENDING_VERIFICATION') {
          this.router.navigate(['/account/verify']);
        }
        return true;
      }),
      catchError((error) => {
        return of(false);
      }),
    );
  }

  login(payload: LoginPayload): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(environment.apiHost + '/api/login', {
      username: payload.username,
      password: payload.password,
      rememberMe: payload.rememberMe,
    });
  }

  resendVerificationEmail(email: string) {
    return this.httpClient.post<'RESENT' | 'ALREADY_VERIFIED' | 'ALREADY_SENT'>(
      environment.apiHost + '/api/profile/verify/resend',
      email,
    );
  }

  verifyEmail(token: string) {
    return this.httpClient.post<'SUCCESS' | 'ALREADY_VERIFIED' | 'TOKEN_INVALID' | 'TOKEN_EXPIRED' | 'TOKEN_NOT_FOUND'>(
      environment.apiHost + '/api/profile/verify',
      token,
    );
  }

  resetPassword(email: string) {
    return this.httpClient.post(environment.apiHost + '/api/profile/reset-password', email);
  }

  updateResetPassword(password: string, token: string) {
    return this.httpClient.post(environment.apiHost + '/api/profile/update-password', {
      password: password,
      token: token,
    });
  }

  updateEmail(value: string, token: string) {
    return this.httpClient.post<string>(environment.apiHost + '/api/profile/update-email', {
      newEmail: value,
      token: token,
    });
  }

  confirmNewEmail(token: string) {
    return this.httpClient.post<string>(environment.apiHost + '/api/profile/new-email-confirm', {
      token: token,
    });
  }

  register(payload: any) {
    return this.httpClient.post<LoginResponse>(environment.apiHost + '/api/register', payload);
  }

  getProfile(): Profile {
    return this.profile;
  }
}
