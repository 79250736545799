import { Component } from '@angular/core';
import { ConfirmationService } from '@phothor/shared/services/confirmation.service';
import { ConfirmationMessage } from '@phothor/shared/messages/confirmation-message';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  confirmation: ConfirmationMessage | null = null;
  isOpen: boolean = false;

  constructor(private confirmationService: ConfirmationService) {
    this.confirmationService.$confirmation.subscribe((val) => {
      this.confirmation = val;
      this.isOpen = true;
    });
  }

  reject() {
    this.isOpen = false;
    this.confirmation?.reject();
    setTimeout(() => {
      this.confirmation = null;
    }, 300);
  }

  confirm() {
    this.isOpen = false;
    this.confirmation!.confirm();
  }
}
