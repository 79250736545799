import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GalleriesService } from '@phothor/manage/galleries/state/galleries.service';
import { NotificationService } from '@phothor/shared/services/notification.service';
import { Destroyer } from '@phothor/shared/abstract/destroyer';
import { Gallery } from '@phothor/manage/gallery/state/gallery';
import { debounceTime, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
})
export class ShareModalComponent extends Destroyer implements OnInit {
  @ViewChild('galleryUrlInput') shareUrl!: ElementRef;
  isOpen: boolean = false;
  form!: FormGroup;
  emailForm!: FormGroup;

  gallery!: Gallery;
  toolTipVisible: boolean = false;

  sendingEmail = false;

  saving = false;
  processed = false;
  processedFail = false;

  siteKey: string;
  captchaFailed = false;
  showCaptcha = false;

  constructor(
    private fb: FormBuilder,
    private galleriesService: GalleriesService,
    private notificationService: NotificationService,
  ) {
    super();
    this.siteKey = environment.turnstileSiteKey;
  }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(254)]],
      captcha: ['', [Validators.required]],
    });
    this.emailForm['controls']['email'].valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200)).subscribe(() => {
      if (this.emailForm.get('email')?.valid) {
        this.showCaptcha = true;
      } else {
        this.showCaptcha = false;
        this.emailForm.get('captcha')?.reset();
      }
    });

    this.form = this.fb.group({
      isShared: true,
      allowDownload: [false],
      downloadQuality: [{ value: null }],
      sharePassword: [''],
    });

    this.form
      .get('downloadQuality')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.form.get('allowDownload')?.setValue(value !== null, { emitEvent: false });
      });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (this.form.valid) {
        this.saving = true;
        this.galleriesService.updateShare(this.gallery.id, this.form.value).subscribe((gallery) => {
          this.galleriesService.shareModalGalleryUpdatedSource.next(gallery);
          this.processedAnimate();
        });
      }
    });

    this.galleriesService.$shareModal.pipe(takeUntil(this.destroy$)).subscribe((gallery: Gallery) => {
      this.gallery = gallery;
      this.isOpen = true;
      document.getElementsByTagName('body').item(0)!.style.overflow = 'hidden';
      this.form.patchValue(
        {
          isShared: true,
          allowDownload: this.gallery.allowDownload,
          downloadQuality: this.gallery.downloadQuality,
          sharePassword: this.gallery.sharePassword,
        },
        { emitEvent: false },
      );
    });
  }

  captchaResolved($event: string | null) {
    this.emailForm.patchValue({ captcha: $event });
  }

  captchaErrored($event: ErrorEvent) {
    console.error('captcha error', $event);
    this.captchaFailed = true;
  }

  processedAnimate(fail = false) {
    this.saving = false;
    if (fail) this.processedFail = true;
    if (!fail) this.processed = true;
    setTimeout(() => {
      this.processed = false;
      this.processedFail = false;
    }, 2000);
  }

  sendEmail() {
    if (this.emailForm.valid) {
      this.saving = true;
      this.sendingEmail = true;
      this.galleriesService.emailShareLink(this.gallery.id, this.emailForm.value).subscribe({
        next: () => {
          this.sendingEmail = false;
          this.processedAnimate();
          this.emailForm.reset();
          this.notificationService.showMessage({
            summary: 'Email sent',
            detail: 'Your client will receive an email with the link to the gallery.',
            color: 'green',
            icon: 'check',
          });
        },
        error: () => {
          this.sendingEmail = false;
          this.processedAnimate(true);
          this.notificationService.showMessage({
            summary: 'Error',
            detail: 'There was an error sending the email',
            color: 'rose',
            icon: 'alert-triangle',
          });
        },
      });
    }
  }

  copyUrlToClipboard() {
    this.shareUrl.nativeElement.select();
    navigator.clipboard.writeText(this.gallery.shareUrl!).then(() => {
      this.toolTipVisible = true;
      setTimeout(() => {
        this.toolTipVisible = false;
      }, 2000);
    });
  }

  // generates a random password which is 5 characters long and easy to remember
  generatePassword() {
    const length = 5;
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.form.get('sharePassword')!.setValue(retVal);
  }

  removePassword() {
    this.form.get('sharePassword')!.setValue(null);
  }

  closeModal() {
    this.isOpen = false;
    document.getElementsByTagName('body').item(0)!['style'].removeProperty('overflow');
    this.emailForm.reset();
  }

  previewGallery() {
    window.open(this.gallery.shareUrl!, '_blank');

  }
}
