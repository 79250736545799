import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AbstractEntityService } from '@phothor/shared/abstract/abstract-entity.service';
import { AuthState } from '@phothor/auth/state/auth.state';
import { Profile } from '@phothor/auth/state/profile';
import { Preferences } from '@phothor/manage/settings/preferences';
import { UploadData } from '@phothor/manage/gallery/state/upload.data';
import { environment } from '../../../environments/environment';
import { last, map, tap } from 'rxjs';

interface ProfileForm {
  firstName: string;
  lastName: string;
  companyName: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends AbstractEntityService<Profile> {
  constructor(
    http: HttpClient,
    private authState: AuthState,
  ) {
    super('/api/profile', http);
  }

  requestEmailChange() {
    return this.post('request-email-change', '');
  }

  updateProfile(value: ProfileForm) {
    return this.put('', JSON.stringify(value));
  }

  updatePreferences(value: Preferences) {
    return this.http.put<Preferences>(`${this.apiUrl}${this.basePath}/preferences`, value);
  }

  updateDomain(domain: string) {
    return this.post('update-domain', JSON.stringify({ domain: domain }));
  }

  updateOnboarding(onboardingActive: boolean) {
    return this.post('update-onboarding', JSON.stringify({ onboardingActive }));
  }

  removeDomain(domain: string) {
    return this.post('remove-domain', '');
  }

  uploadLogo(data: UploadData, progressCallback: (progress: number) => void) {
    const url = `${environment.uploadHost}/logo?${data.toQuery()}`;

    const headers = new HttpHeaders({
      'Content-Type': data.file.type,
    });

    const req = new HttpRequest('POST', url, data.file, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request<{ path: string }>(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
          progressCallback(progress);
        }
      }),
      last(),
      map((event) => {
        if (event.type === HttpEventType.Response) {
          if (event.status !== 200) {
            throw new Error('Upload failed: ' + event.status);
          } else {
            return event.body as { path: string };
          }
        }
        throw new Error('Upload failed');
      }),
    );
  }

  uploadAvatar(data: UploadData, progressCallback: (progress: number) => void) {
    const url = `${environment.uploadHost}/avatar?${data.toQuery()}`;

    const headers = new HttpHeaders({
      'Content-Type': data.file.type,
    });

    const req = new HttpRequest('POST', url, data.file, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request<{ path: string }>(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
          progressCallback(progress);
        }
      }),
      last(),
      map((event) => {
        if (event.type === HttpEventType.Response) {
          if (event.status !== 200) {
            throw new Error('Upload failed: ' + event.status);
          } else {
            return event.body as { path: string };
          }
        }
        throw new Error('Upload failed');
      }),
    );
  }

  removeLogo() {
    return this.http.delete<Preferences>(`${this.apiUrl}${this.basePath}/logo`);
  }

  removeProfilePicture() {
    return this.http.delete<Preferences>(`${this.apiUrl}${this.basePath}/avatar`);
  }
}
