{
  "1" : {
    "completed": false,
    "title": "Add Your Photos! \uD83D\uDCF8✨",
    "description": "Time to make your gallery shine! Tap this button to upload your images.",
    "placement": "left"
  },
  "2": {
    "completed": false,
    "title": "Organize Your Photos \uD83D\uDCC2",
    "description": "Create folders to keep things tidy! Perfect for sorting by location or separating edited photos from raw ones",
    "placement": "bottom"
  },
  "3": {
    "completed": false,
    "title": "Add a Cover Photo \uD83D\uDDBC\uFE0F",
    "description": "Make your gallery stand out! Choose a cover photo here.",
    "placement": "right"
  },
  "4": {
    "completed": false,
    "title": "Edit Gallery Title ✏\uFE0F",
    "description": "Personalize your gallery by editing the title directly!",
    "placement": "bottom"
  },
  "5": {
    "completed": false,
    "title": "Tweak Your Gallery Settings ⚙\uFE0F",
    "description": "Fine-tune your gallery settings by clicking this button.",
    "placement": "left"
  },
  "6": {
    "completed": false,
    "title": "Share Your Gallery \uD83C\uDF10",
    "description": "Get the link, set a password, email it, and even control download limits—all in one place!",
    "placement": "right"
  }
}
