export class NotificationMessage {
  color:  'white' | 'blue' | 'yellow' | 'rose' | 'green';
  summary: string;
  detail: string;
  life: number;
  icon: string = '';
  isClosing: boolean = false;
  isOpening: boolean = true;

  constructor(options: NotificationMessageOptions) {
    this.summary = options.summary;
    this.detail = options.detail || '';
    this.color = options.color || 'white';
    this.life = options.life || 5;
    this.icon = options.icon || '';
    setTimeout(() => {
      this.isOpening = false;
    }, 300);
  }
}

export interface NotificationMessageOptions {
  summary: string;
  detail?: string;
  color?: 'white' | 'blue' | 'yellow' | 'rose' | 'green';
  life?: number;
  icon?: string;
}
