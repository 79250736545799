{
  "name": "client-gallery-ui",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration=$CONFIGURATION && npm run sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org phothor --project client-gallery-ui-prod ./dist && sentry-cli sourcemaps upload --org phothor --project client-gallery-ui-prod ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.4",
    "@angular/common": "^17.0.4",
    "@angular/compiler": "^17.0.4",
    "@angular/core": "^17.0.4",
    "@angular/forms": "^17.0.4",
    "@angular/platform-browser": "^17.0.4",
    "@angular/platform-browser-dynamic": "^17.0.4",
    "@angular/router": "^17.0.4",
    "@fontsource/darker-grotesque": "^5.0.20",
    "@fontsource/inter": "^5.0.19",
    "@fontsource/public-sans": "^5.0.18",
    "@js-joda/timezone": "^2.18.2",
    "@micro-sentry/angular": "^7.1.0",
    "@ngneat/helipopper": "^9.2.1",
    "@sentry/cli": "^2.36.1",
    "@stripe/stripe-js": "github:stripe/stripe-js",
    "axios": "^1.6.2",
    "dayjs": "^1.11.10",
    "lightgallery": "^2.7.2",
    "litepicker": "^2.0.12",
    "lucide-angular": "^0.341.0",
    "muuri": "^0.9.5",
    "ngx-matomo-client": "^6.2.0",
    "ngx-stripe": "^17.0.1",
    "ngx-turnstile": "^0.2.1",
    "posthog-js": "^1.154.5",
    "remove": "^0.1.5",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.3",
    "@angular/cli": "~17.0.3",
    "@angular/compiler-cli": "^17.0.4",
    "@tailwindcss/forms": "^0.5.7",
    "@tailwindcss/nesting": "^0.0.0-insiders.565cd3e",
    "@types/jasmine": "~4.3.0",
    "autoprefixer": "^10.4.16",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.31",
    "prettier": "^3.1.0",
    "tailwindcss": "^3.3.5",
    "typescript": "~5.2.2"
  }
}
