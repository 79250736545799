@if (isOpen) {
  <div
    [ngClass]="{ 'show': isOpen }"
    class="z-[100] modal overflow-auto group bg-gradient-to-b from-theme-1/50 via-theme-2/50 to-black/50 transition-[visibility,opacity] w-screen h-screen fixed left-0 top-0
     [&:not(.show)]:duration-[0s,0.2s] [&:not(.show)]:delay-[0.2s,0s] [&:not(.show)]:invisible [&:not(.show)]:opacity-0 [&.show]:visible [&.show]:opacity-100
      [&.show]:duration-[0s,0.4s]">
    <div
      class="p-5 w-[90%] mx-auto bg-white relative rounded-md shadow-md transition-[margin-top,transform] duration-[0.4s,0.3s] -mt-16 group-[.show]:mt-16
     group-[.modal-static]:scale-[1.05] dark:bg-darkmode-600 sm:w-[600px] text-center">

      <div class="p-1 text-center">
        <i data-lucide="x-circle"
           class="stroke-[1] w-5 h-5 mx-auto mt-3 h-16 w-16 text-danger mx-auto mt-3 h-16 w-16 text-danger"></i>
        <div class="mt-5 text-3xl">{{ confirmation?.summary }}</div>
        <div class="mt-2 text-slate-500"
             [innerHTML]="confirmation?.detail"
        >
        </div>
      </div>
      <div class="mt-6 px-5 pb-4 text-center">
        <button type="button"
                (click)="reject()"
                class="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-24 mr-1 w-24">
          Cancel
        </button>
        <button type="button"
                (click)="confirm()"
                class="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-warning border-warning text-white dark:border-warning w-24 w-24">
          Confirm
        </button>
      </div>
    </div>
  </div>
}
