import {inject, Injectable} from '@angular/core';
import {TippyService} from "@ngneat/helipopper";
import * as data from '../../../assets/json/onboarding_steps.json';
import {
  OnboardingMessagesComponent
} from "@phothor/shared/messages/components/onboarding-messages/onboarding-messages.component";
import {ProfileService} from "@phothor/manage/settings/profile.service";

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {

  public steps: any = (data as any).default;

  private tippyService = inject(TippyService)
  constructor(
    public profileService: ProfileService
  ) {
  }

  public getActiveStep() {
    for (let i = 1; i <= Object.keys(this.steps).length; i++) {
      if (!this.steps[i].completed) {
        return i;
      }
    }
    return null;
  }

  public startOnboarding() {
    const activeStep = this.getActiveStep();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('onboarding-active');
    if (activeStep) {
      setTimeout(() => {
        this.showStep(activeStep);
      }, 200);
    }
  }

  public stopOnboarding() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('onboarding-active');

    for (let i = 1; i <= Object.keys(this.steps).length; i++) {
      this.steps[i].tippyInstance?.destroy();
    }

    this.profileService.updateOnboarding(false).subscribe();

  }

  public nextStep() {
    const activeStep = this.getActiveStep();
    if (activeStep) {
      this.steps[activeStep].completed = true;
      this.steps[activeStep].tippyInstance?.hide();
      if(activeStep + 1 > Object.keys(this.steps).length) {
        this.stopOnboarding();

        return;
      }
      this.showStep(activeStep + 1);
    }
  }

  public showStep(stepNumber: number) {

    const step =this.steps[stepNumber];
    const tippySelector = document.getElementById(`onboarding-step-${stepNumber}`);

    let tippyInstance = null;
    if (tippySelector) {
      tippyInstance = this.tippyService.create(tippySelector, OnboardingMessagesComponent, {
        variation: 'popper',
        hideOnClick: true,
        placement: window.screen.width < 500 ? 'bottom' : step.placement,
        animation: 'scale',
        maxWidth: window.screen.width < 500 ? 250 : 350,
        data: {
          title: step.title,
          description: step.description,
        },
        onClickOutside: (instance: any, event: any) => {
          this.stopOnboarding();
        }
      });
    }

    this.steps[stepNumber].tippyInstance = tippyInstance;

    tippyInstance?.show();
  }

}
