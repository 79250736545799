@if (isOpen) {

  <div [ngClass]="{ 'show': isOpen }"
       aria-hidden="true" tabindex="-1" id="header-footer-modal-preview"
       class="modal overflow-auto group bg-gradient-to-b from-theme-1/50 via-theme-2/50 to-black/50 transition-[visibility,opacity] w-screen h-screen fixed left-0 top-0
        [&:not(.show)]:duration-[0s,0.2s] [&:not(.show)]:delay-[0.2s,0s] [&:not(.show)]:invisible [&:not(.show)]:opacity-0 [&.show]:visible [&.show]:opacity-100
         [&.show]:duration-[0s,0.4s] z-[55]">
    <div
      class="w-[90%] mb-20 mx-auto bg-white relative rounded-md shadow-md transition-[margin-top,transform] duration-[0.4s,0.3s] -mt-24 group-[.show]:mt-24
      group-[.modal-static]:scale-[1.05] dark:bg-darkmode-600 sm:w-[600px]">
      <div class="flex flex-col items-center px-5 py-3 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 class="text-base font-medium">
          Gallery Share Options
        </h2>
        <div class="absolute left-4">
          @if (processed && !saving) {
            <lucide-icon name="check" size="20" class="text-success animate-pulse duration-200"></lucide-icon>
          }
          @if (processedFail && !saving) {
            <lucide-icon name="x-circle" size="20" class="text-danger animate-pulse duration-200"></lucide-icon>
          }
          @if (saving) {
            <lucide-icon name="loader" size="20" class="text-primary animate-spin"></lucide-icon>
          }
        </div>
        <div class="absolute right-2">
          <button
            (click)="closeModal()"
            type="button"
            class="transition duration-200 inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4
          focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90
          [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-slate-500
          dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1">
            <lucide-icon name="x" size="20"></lucide-icon>
          </button>
        </div>
      </div>

      <ng-container [formGroup]="form">
        <div class="p-5 grid grid-cols-12 gap-4 gap-y-3">
          <div class="col-span-12 relative">
            <label for="share-url"
                   class="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
              Share Url
            </label>
            <div class="flex gap-2 items-center justify-center">
              <div class="w-full relative">
                <input
                  [value]="gallery.shareUrl"
                  [tp]="'Copied to clipboard!'"
                  [tpTrigger]="'manual'"
                  [tpIsVisible]="toolTipVisible"
                  #galleryUrlInput
                  (click)="copyUrlToClipboard()"
                  [readonly]="true"
                  type="text"
                  id="share-url"
                  class="cursor-text disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100
            [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full
            text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary
            focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80
            group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l
            group-[.input-group]:last:rounded-r group-[.input-group]:z-10">
                <button (click)="copyUrlToClipboard()"
                        class="absolute bg-slate-100 top-1 right-1 flex h-8 w-8 items-center justify-center">
                  <lucide-icon name="copy" size="20" class="text-primary"></lucide-icon>
                </button>
              </div>

              <button (click)="previewGallery()" [tp]="'Preview gallery'"
                      class="h-9 w-9 bg-slate-100 text-sm border-slate-200 shadow-sm rounded-md flex items-center justify-center border">
                <lucide-icon  name="eye" size="20" class="text-primary"></lucide-icon>
              </button>
            </div>

            <div class="text-sm text-slate-500 mt-3">
              Click to copy the link to your clipboard. You can share this link with anyone to give them access to
              this gallery.
            </div>


          </div>
        </div>
        <div class="p-5 grid grid-cols-12 gap-4 gap-y-3">
          <div class="col-span-12 relative">
            <label for="share-password"
                   class="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
              Share Password
            </label>
            <input
              formControlName="sharePassword"
              maxlength="20"
              minlength="5"
              id="share-password"
              class="cursor-text disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100
            [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full
            text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary
            focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80
            group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l
            group-[.input-group]:last:rounded-r group-[.input-group]:z-10">
            <div class="text-sm text-slate-500 mt-3">
              Set a password to protect your gallery from unauthorized access. Leave it empty to disable password protection.
            </div>
            <button (click)="generatePassword()"
                    [tp]="'Generate a new password'"
                    class="absolute top-8 right-1 flex h-8 w-8 items-center justify-center">
              <lucide-icon name="refresh-cw" size="20" class="text-primary"></lucide-icon>
            </button>
            <button *ngIf="form.get('sharePassword')?.value" (click)="removePassword()"
                    [tp]="'Remove password'"
                    class="absolute top-8 right-8 mr-0.5 flex h-8 w-8 items-center justify-center">
              <lucide-icon name="x-circle" size="20" class="text-primary"></lucide-icon>
            </button>
          </div>
        </div>

        <div class="p-5 grid grid-cols-12 gap-4 gap-y-3">
          <div class="col-span-12">
            <label for="quality-select"
                   class="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
              Downloads
            </label>
            <div class="flex items-center">
              <select
                id="quality-select"
                formControlName="downloadQuality"
                class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm
                      [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed
                      [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70
                      [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50
                      "
              >
                <option [ngValue]="null">Disable Downloads</option>
                <option value="ORIGINAL">Original Size</option>
                <option value="XL">Extra Large - 2500px</option>
                <option value="L">Large - 1280px</option>
              </select>
            </div>
            <div class="text-sm text-slate-500 mt-3">
              Choose the quality of the images that can be downloaded from this gallery.
            </div>
            <app-error-messages [control]="form.get('downloadQuality')"></app-error-messages>
          </div>

        </div>

        <!-- horizontal line -->
        <div class="border-t mx-auto w-11/12 my-2 border-b border-slate-200/60 dark:border-darkmode-400"></div>

        <ng-container [formGroup]="emailForm">
          <div class="p-5 grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12 relative">
              <label for="share-url"
                     class="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                Email Gallery Link
              </label>
              <div class="flex">
                <input
                  type="email"
                  id="email"
                  placeholder="email@example.com"
                  formControlName="email"
                  class="cursor-text disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100
            [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full
            text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary
            focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80
            group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l
            group-[.input-group]:last:rounded-r group-[.input-group]:z-10">
              </div>
              <div class="text-xs text-slate-500 mt-3">
                Enter an email address to send the gallery link to.
              </div>
              <app-error-messages [control]="emailForm.get('email')"></app-error-messages>
              <div>
                @if (showCaptcha) {
                  <div class="items-center flex mt-5">
                    <div class="mx-auto text-center">
                      <ngx-turnstile (resolved)="captchaResolved($event)" (error)="captchaErrored($event)"
                                     [siteKey]="siteKey" [action]="'share-email'" theme="light"
                                     [tabIndex]="0"></ngx-turnstile>
                      <app-error-messages [control]="form.controls['captcha']"></app-error-messages>
                      @if (captchaFailed) {
                        <div class="text-danger text-sm mt-2">Captcha verification failed. Please try again.</div>
                      }
                    </div>
                  </div>
                }
              </div>
              <div>
                <button
                  (click)="sendEmail()"
                  [disabled]="!emailForm.valid || saving || sendingEmail"
                  type="button"
                  class="transition my-2 duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4
          focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90
          [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500
          dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1">
                  @if (!sendingEmail) {
                    Send Email
                  } @else {
                    Sending Email...
                  }
                </button>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="flex justify-between px-5 py-3 text-right border-t border-slate-200/60 dark:border-darkmode-400">
          <div class="w-1/3">
            <a [routerLink]="['/manage/gallery/'+gallery.id+'/configure/share']" (click)="closeModal()"
               class="flex items-center pt-2 font-medium text-primary"
            >
              More Settings
              <lucide-icon name="move-right" size="20" class="ml-1"></lucide-icon>
            </a>
          </div>
          <div class="w-1/3">
            <button
              (click)="closeModal()"
              type="button"
              class="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4
          focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90
          [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500
          dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 mr-1 w-20">
              Done
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

}
