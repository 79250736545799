import { Injectable } from '@angular/core';
import { Job } from '@phothor/shared/jobs/job';
import { Observable, Subject } from 'rxjs';
import { JobItem } from '@phothor/shared/jobs/job-item';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  private deleteTimeout = 1000;
  jobs: Map<string, Job> = new Map<string, Job>();

  jobAddSubject: Subject<Job> = new Subject<Job>();
  public jobAdd$: Observable<Job> = this.jobAddSubject.asObservable();

  jobDeleteSubject: Subject<Job> = new Subject<Job>();
  public jobDelete$: Observable<Job> = this.jobDeleteSubject.asObservable();

  jobItemAddSubject: Subject<JobItem> = new Subject<JobItem>();
  public jobItemAdd$: Observable<JobItem> = this.jobItemAddSubject.asObservable();

  jobItemDeleteSubject: Subject<JobItem> = new Subject<JobItem>();
  public jobItemDelete$: Observable<JobItem> = this.jobItemDeleteSubject.asObservable();

  jobItemProgressSubject: Subject<JobItem> = new Subject<JobItem>();
  public jobItemProgress$: Observable<JobItem> = this.jobItemProgressSubject.asObservable();

  addJob(job: Job) {
    // check if job already exists
    if (this.jobs.has(job.id)) {
      return;
    }
    this.jobs.set(job.id, job);
    this.jobAddSubject.next(job);
  }

  deleteJob(job: Job) {
    this.jobs.delete(job.id);
    this.jobDeleteSubject.next(job);
  }

  addJobItem(job: Job, item: JobItem) {
    // check if job exists
    if (!this.jobs.has(job.id)) {
      return;
    }
    // get job
    job = this.jobs.get(job.id)!;

    // check if item already exists
    if (job.hasItem(item.id)) {
      return;
    }
    job.items.push(item);
    job.itemsTotal++;
    this.jobItemAddSubject.next(item);
  }

  updateItemProgress(job: Job, itemId: string, progress: number) {
    const jobItem = job.getItem(itemId);
    if (!jobItem) {
      return;
    }
    if (progress > 100) {
      progress = 100;
    }
    jobItem.progress = progress;
    this.jobItemProgressSubject.next(jobItem);
  }

  deleteJobItem(job: Job, itemId: string) {
    const jobItem = job.getItem(itemId);
    if (!jobItem) {
      return;
    }
    job.items = job.items.filter((i) => i.id !== itemId);
    this.jobItemDeleteSubject.next(jobItem);
    if (job.items.length === 0) {
      job.state = 'DONE';
      setTimeout(() => {
        this.deleteJob(job);
      }, this.deleteTimeout);
    }
  }

  itemSuccess(job: Job, itemId: string) {
    const jobItem = job.getItem(itemId);
    if (!jobItem) {
      return;
    }
    if (jobItem.state === 'DONE') {
      return;
    }
    job.itemsCompleted++;
    jobItem.state = 'DONE';
    setTimeout(() => {
      this.deleteJobItem(job, jobItem.id);
    }, this.deleteTimeout);
  }

  itemError(job: Job, itemId: string) {
    const jobItem = job.getItem(itemId);
    if (!jobItem) {
      return;
    }
    if (jobItem.state === 'ERROR') {
      return;
    }
    job.itemsCompleted++;
    jobItem.state = 'ERROR';
    setTimeout(() => {
      this.deleteJobItem(job, jobItem.id);
    }, this.deleteTimeout);
  }
}
