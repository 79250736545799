
<div class="relative flex items-center p-3">
  <div class="flex flex-col gap-2">
    <div class="whitespace-nowrap font-medium leading-normal dark:text-slate-200">
      {{ title }}
    </div>
    <div class="mt-0.5  text-xs text-slate-500 dark:text-slate-400">
      {{ description }}
    </div>
    <div class="flex justify-between gap-8">
      <button
        (click)="stop()"
        class="underline text-gray-700 whitespace-nowrap">
        Skip tutorial
      </button>
      <button
        (click)="nextStep()"
        class="py-2 text-l transition duration-200 border shadow-sm inline-flex items-center justify-center rounded-md font-medium cursor-pointer focus:ring-4
      focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90
      [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary
      [&amp;:hover:not(:disabled)]:bg-primary/10 border-primary/50 px-4 md:text-center">
        Got it
      </button>
    </div>

  </div>
</div>
